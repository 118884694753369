<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('guest_services_settings.add_action.title')"
    context="addGuestServicesSettings"
    @submit="submit"
    @cancel="cancel"
    cancel_text="guest_services_settings.add_action.button_cancel"
    submit_text="guest_services_settings.add_action.button_save"
    :submit_working="working"
    class="smoke-guest-services-settings--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.partner_id"
            v-model="guestServicesSettings.partner_id"
            :label="$t('guest_services_settings.add_action.fields.partner_id')"
            :error-messages="errors.collect('guest_services_settings.partner_id')"
            data-vv-name="guest_services_settings.partner_id"
            :data-vv-as="$t('guest_services_settings.add_action.fields.partner_id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-guest-services-settings--add--partner_id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_payment_notice.fr"
            v-model="guestServicesSettings.consumer_payment_notice.fr"
            :label="$t('guest_services_settings.add_action.fields.consumer_payment_notice.fr')"
            :error-messages="errors.collect('guest_services_settings.consumer_payment_notice.fr')"
            data-vv-name="guest_services_settings.consumer_payment_notice.fr"
            :data-vv-as="$t('guest_services_settings.add_action.fields.consumer_payment_notice.fr')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--add--consumer_payment_notice-fr"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_payment_notice.en"
            v-model="guestServicesSettings.consumer_payment_notice.en"
            :label="$t('guest_services_settings.add_action.fields.consumer_payment_notice.en')"
            :error-messages="errors.collect('guest_services_settings.consumer_payment_notice.en')"
            data-vv-name="guest_services_settings.consumer_payment_notice.en"
            :data-vv-as="$t('guest_services_settings.add_action.fields.consumer_payment_notice.en')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--add--consumer_payment_notice-en"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="guestServicesSettings.features.print_generate_voucher" :label="$t('guest_services_settings.add_action.fields.features.print_generate_voucher')" class="smoke-guest-services-settings--add--print_generate_voucher" />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="guestServicesSettings.features.cancel_voucher" :label="$t('guest_services_settings.add_action.fields.features.cancel_voucher')" class="smoke-guest-services-settings--add--cancel_voucher" />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'add-guest-services-settings-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      guestServicesSettings: {
        partner_id: '',
        consumer_payment_notice: {
          en: '',
          fr: '',
        },
        features: {
          print_generate_voucher: true,
          cancel_voucher: true,
        },
      },
    };
  },
  methods: {
    resetForm() {
      this.guestServicesSettings = {
        partner_id: '',
        consumer_payment_notice: {
          en: '',
          fr: '',
        },
        features: {
          print_generate_voucher: true,
          cancel_voucher: true,
        },
      };
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'guestServicesSettings/createGuestServicesSettings',
            name: 'addGuestServicesSettings',
            success: this.success,
          },
          this.guestServicesSettings,
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
