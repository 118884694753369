module.exports = {
  agent: {
    add_agent_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        email: 'Email',
        first_name: 'First Name',
        groups: 'Control Access Groups',
        language: 'Language',
        last_name: 'Last Name',
        mobile_number: 'Mobile Number',
        notifications: 'Notifications',
        team: 'Team',
      },
      groups: 'Control Access Groups',
      notifications: 'Notifications',
      success: 'Agent Created',
      title: 'Add Agent',
    },
    attach_program_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        programs: 'Programs',
      },
      notifications: 'Notifications',
      permissions: 'Permissions',
      success: 'Program Added',
      title: 'Attach Program',
    },
    delete_agent_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      success: 'Agent Deleted',
      text: 'Are you sure you want to delete this agent?',
      title: 'Delete Agent',
    },
    edit_permissions_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      notifications: 'Notifications',
      permissions: 'Permissions',
      success: 'Permissions Saved',
      title: 'Edit Permissions',
    },
    form: {
      add: 'Add',
      clear: 'clear',
      exclude_deactivated: 'Exclude Deactivated',
      label: 'Agent Name or Email',
      submit: 'search',
      title: 'Search for an agent',
    },
    list: {
      title: 'Agents',
    },
    listTable: {
      delete: 'Delete',
      edit: 'Edit',
      email: 'Email',
      mobile: 'Mobile',
      name: 'Name',
      program: {
        assign: 'Assign Program',
      },
      programs: 'Programs',
      team: 'Team',
      undelete: 'Undelete',
    },
    remove_program_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      fields: {
        programs: 'Programs',
      },
      success: 'Program Removed',
      text: 'Are you sure you want to remove this program?',
      title: 'Remove Program',
    },
    undelete_agent_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      success: 'Agent Undeleted',
      text: 'Are you sure you want to undelete this agent?',
      title: 'Undelete Agent',
    },
    update_agent_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        email: 'Email',
        first_name: 'First Name',
        groups: 'Control Access Groups',
        language: 'Language',
        last_name: 'Last Name',
        mobile_number: 'Mobile Number',
        notifications: 'Notifications',
        team: 'Team',
      },
      groups: 'Control Access Groups',
      success: 'Agent Updated',
      title: 'Update Agent',
    },
  },
  alert: {
    action: 'Action',
    edit: 'Edit',
    email_status: 'Email',
    list: {
      title: 'Alerts',
    },
    messages: {
      en: 'English',
      fr: 'French',
      push: 'Push Notification',
      push_message: 'Message',
      push_title: 'Title',
      title: 'Default Messages',
    },
    push: {
      fields: {
        push: 'Push',
      },
    },
    push_android: 'Push Android Block',
    push_apns: 'Push Apns Block',
    push_data: 'Push Data Block',
    push_status: 'Push',
    sms_status: 'Built-In SMS',
    status: 'Status',
    title: {
      en: 'English',
      fr: 'French',
    },
    update_alert_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        authorization_header: 'Webhook Authorization Header',
        email: 'Email',
        email_fields: {
          template_id_en: 'English Template Id',
          template_id_fr: 'French Template Id',
        },
        message_en: 'English Message',
        message_fr: 'French Message',
        push: 'Push',
        push_fields: {
          android: 'Android (JSON template)',
          apns: 'Apns (JSON template)',
          data: 'Data (JSON template)',
          message_en: 'English Message',
          message_fr: 'French Message',
          title_en: 'English Title',
          title_fr: 'French Title',
        },
        sms: 'Built-In SMS',
        webhook: 'Webhook',
        webhook_url: 'Webhook URL',
      },
      help: 'Help',
      success: 'Alert Updated',
      title: 'Edit Alert',
    },
    webhook: {
      authorization_header: 'Authorization Header',
      title: 'Webhook',
      url: 'URL',
    },
    webhook_status: 'Webhook',
  },
  app: {
    add_app_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        name: 'Name',
        organization_key: 'Organization',
        partner_id: 'Partner Id',
        roles: 'Roles',
        whitelist_cidr: 'Whitelist Cidrs',
      },
      organizations: 'Organizations',
      roles: 'Roles',
      success: 'App Created',
      title: 'Add App',
      unique_app_name_error_msg: 'App name already exists. Please choose another name.',
      whitelist_cidrs_error_msg: 'Whitelist Cidrs are required.',
      whitelist_cidrs_label: 'Whitelist Cidrs',
    },
    delete_app_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      success: 'App Deleted',
      text: 'Are you sure you want to delete this app?',
      title: 'Delete App',
    },
    form: {
      add: 'Add',
      clear: 'clear',
      label: 'App Name',
      submit: 'search',
      title: 'Search for an app',
    },
    get_api_secret: {
      button_cancel: 'Close',
      label: 'Api Secret',
      title: 'Api Secret',
    },
    list: {
      title: 'Apps',
    },
    listTable: {
      api_key: 'Api Key',
      delete: 'Delete',
      edit: 'Edit',
      name: 'Name',
      partner_id: 'Partner Id',
      programs: 'Programs',
      show_secret: 'Show Secret',
    },
    update_app_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        name: 'Name',
        organization_key: 'Organization',
        partner_id: 'Partner Id',
        roles: 'Roles',
        whitelist_cidr: 'Add Whitelist Cidrs',
      },
      organizations: 'Organizations',
      roles: 'Roles',
      success: 'App Updated',
      title: 'Update App',
      unique_app_name_error_msg: 'App name already exists. Please choose another name.',
      whitelist_cidrs_error_msg: 'Whitelist Cidrs are required.',
      whitelist_cidrs_label: 'Whitelist Cidrs',
    },
  },
  app_key: 'App Key',
  branding_category: {
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        id: 'Id',
        name: {
          en: 'Name EN',
          fr: 'Name FR',
        },
      },
      success: 'Publisher Branding Created',
      title: 'Add Branding Category',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Branding Categories',
    },
    listTable: {
      edit: 'Edit',
      id: 'Id',
      name: {
        en: 'Name EN',
        fr: 'Name FR',
      },
    },
    update_action: {
      fields: {
        id: 'Id',
        name: {
          en: 'Name EN',
          fr: 'Name FR',
        },
      },
      success: 'Publisher Branding Updated',
      title: 'Update Branding Category',
    },
  },
  code: {
    alert: {
      email: {
        false: 'Inactive',
        true: 'Active',
      },
      id: {
        alert_update_trigger: 'Alert Update Trigger',
        authorization_approved: 'Authorization Approved',
        authorization_approved_closed_loop: 'Authorization Approved with Closed Loop',
        card_activation_reminder: 'Card Activation Reminder',
        card_application_approved: 'Card Application Approved',
        credits_refunded: 'Credits Refunded',
        give_reward: 'Give Reward',
        hello_new_offer: 'New Offer',
        insufficient_funds: 'Insufficient Funds',
        load_interac: 'Load Interac',
        push_inactive: 'Push Inactive (Not a Real Trigger)',
        wallet_loaded: 'Wallet Loaded',
      },
      push: {
        false: 'Inactive',
        true: 'Active',
      },
      sms: {
        false: 'Inactive',
        true: 'Active',
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
      },
      webhook: {
        false: 'Inactive',
        true: 'Active',
      },
    },
    notifications: {
      aml: 'AML',
      application: 'Application',
      card: 'Card',
      real_time: 'Real Time',
    },
    roles: {
      admin: 'Admin',
      aml: 'AML',
      aml_officer: 'AML Officer',
      authenticated: 'Authenticated',
      ehi: 'Ehi',
      job: 'Job',
      public: 'Public',
      simulator: 'Simulator',
      superadmin: 'Superadmin',
      support: 'Support',
      test: 'Test',
      webhook: 'Webhook',
    },
  },
  country: {
    canada: 'Canada',
  },
  errors: {
    app: {
      render: 'Application Rendering Error: {message}',
      store: 'Unexpected Error In the Store: {message}',
    },
    http: {
      agent_deletion: 'Error: Could not delete agent as agent was not found',
      agent_duplicate_email: '{message}',
      app_deletion: 'Error: Could not delete app as app was not found',
      branding_category_id_already_exists: 'A branding category with the given id already exists',
      guest_services_settings_id_already_exists: 'A guest services setting with the given partner id already exists',
      guest_services_settings_not_found: 'The guest services settings with the given partner id could not be found',
      note_type_id_already_exists: 'A note type with the given id already exists',
      package_deletion: 'Error: Could not delete package as package was not found',
      space_id_already_exists: 'A space with the given slug already exists',
      system_resource_not_found: 'System Resource Not Found: {message}',
    },
    system: {
      system_bad_gateway: 'Could not reach the api for some reason',
      system_duplicate_key_found: 'Duplicate key found',
      system_forbidden: 'Forbidden: {message}',
      system_resource_not_found: 'Not found',
      system_unauthorized: 'Unauthorized: {message}',
      validation_error: 'Validation Error: {message}',
    },
    systemError: 'SYSTEM ERROR: ',
  },
  group: {
    add_group_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        id: 'Id',
        name: 'Name',
        permissions: 'Permissions',
      },
      success: 'Group Created',
      title: 'Add Group',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Access Groups',
    },
    listTable: {
      edit: 'Edit',
      id: 'Id',
      name: 'Name',
    },
    update_group_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        id: 'Id',
        name: 'Name',
        permissions: 'Permissions',
      },
      success: 'Group Updated',
      title: 'Update Group',
    },
  },
  guest_services_settings: {
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        consumer_payment_notice: {
          en: 'Consumer Payment Notice EN',
          fr: 'Consumer Payment Notice FR',
        },
        features: {
          cancel_voucher: 'Cancel Voucher',
          print_generate_voucher: 'Print Generate Voucher',
        },
        partner_id: 'Partner Id',
      },
      success: 'Guest Services Settings Created',
      title: 'Add Guest Services Settings',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Guest Services Settings',
    },
    listTable: {
      consumer_payment_notice: {
        en: 'Consumer Payment Notice EN',
        fr: 'Consumer Payment Notice FR',
      },
      edit: 'Edit',
      features: {
        cancel_voucher: 'Cancel Voucher',
        print_generate_voucher: 'Print Generate Voucher',
      },
      partner_id: 'Partner Id',
    },
    update_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        consumer_payment_notice: {
          en: 'Consumer Payment Notice EN',
          fr: 'Consumer Payment Notice FR',
        },
        features: {
          cancel_voucher: 'Cancel Voucher',
          print_generate_voucher: 'Print Generate Voucher',
        },
        partner_id: 'Partner Id',
      },
      success: 'Guest Services Settings Updated',
      title: 'Update Guest Services Settings',
    },
  },
  language: {
    english: 'English',
    french: 'French',
  },
  navigation: {
    agent: 'Agent',
    alert: 'Alert',
    app: 'App',
    branding_category: 'Branding Category',
    group: 'Group',
    guest_services_settings: 'Guest Services Settings',
    login: 'Login',
    logout: 'Logout',
    note_type: 'AML Note Type',
    package: 'Package',
    partner: 'Partner',
    program: 'Program',
    space: 'Space',
    status: 'Status',
    subscription_plans: 'Subscription Plans',
    virtual_money: 'Virtual Money',
  },
  noAccess: {
    message: 'Please contact your technical support agent to give you the access to console features.',
  },
  note_type: {
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        active: 'Active',
        category: 'Category',
        id: 'ID',
        name: {
          en: 'Name (en)',
          fr: 'Name (fr)',
        },
      },
      title: 'Edit Note Type',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'AML Note Types',
    },
    listTable: {
      active: 'Active',
      category: 'Category',
      edit: 'Edit',
      id: 'ID',
      name: 'Name',
    },
    update_action: {
      fields: {
        active: 'Active',
        name: {
          en: 'Name (en)',
          fr: 'Name (fr)',
        },
      },
      title: 'Edit Note Type',
    },
  },
  package: {
    activate_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      success: 'Card Package Activated',
      text: 'Are you sure you want to activate this card-package?',
      title: 'activate',
    },
    add_package_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        bulk: 'Bulk',
        card_design: 'Card Design',
        card_product_id: 'Card Product Id',
        carrier_type: 'Carrier Type',
        id: 'Id',
        logo_back_id: 'Logo Back Id',
        logo_front_id: 'Logo Front Id',
        name: 'Name',
        product_ref: 'Product Ref',
        program_key: 'Program Key',
        programs: 'Programs',
      },
      success: 'Package Created',
      title: 'Add Package',
    },
    deactivate_action: {
      button_cancel: 'Cancel',
      button_save: 'Confirm',
      success: 'Card Package Deactivated',
      text: 'Are you sure you want to deactivate this card-package?',
      title: 'deactivate',
    },
    form: {
      add: 'Add',
    },
    list: {
      filters: {
        submit: 'Search',
        without_deactivated: 'Exclude Deactivated',
      },
      title: 'Card Packages',
    },
    listTable: {
      activate: 'Activate',
      bulk: 'Bulk',
      card_design: 'Card Design',
      card_product_id: 'Card Product Id',
      carrier_type: 'Carrier Type',
      deactivate: 'Deactivate',
      edit: 'Edit',
      id: 'Id',
      logo_back_id: 'Logo Back Id',
      logo_front_id: 'Logo Front Id',
      name: 'Name',
      product_ref: 'Product Ref',
      program_key: 'Program Key',
      status: {
        title: 'Status',
        values: {
          active: 'Active',
          deactivated: 'Deactivated',
          undefined: 'No Status',
        },
      },
    },
    update_package_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        bulk: 'Bulk',
        card_design: 'Card Design',
        card_product_id: 'Card Product Id',
        carrier_type: 'Carrier Type',
        id: 'Id',
        logo_back_id: 'Logo Back Id',
        logo_front_id: 'Logo Front Id',
        name: 'Name',
        product_ref: 'Product Ref',
        program_key: 'Program Key',
        programs: 'Programs',
      },
      success: 'Package Updated',
      title: 'Update Package',
    },
  },
  pageNotFound: {
    message: 'The page you trying to get does not exist!',
  },
  page_title: {
    agent: 'Back-Office | Hello - Agents',
    alert: 'Back-Office | Hello - Alerts',
    app: 'Back-Office | Hello - Apps',
    branding_category: 'Back-Office | Hello - Branding Categories',
    group: 'Group | Hello - Group',
    guest_services_settings: 'Back-Office | Hello - Guest Services Settings',
    login: 'Back-Office | Hello - Login',
    noAccess: 'Back-Office | Hello - No Access',
    note_type: 'Back-Office | Hello - Note Types',
    organization: 'Back-Office | Hello - Organizations',
    package: 'Back-Office | Hello - Packages',
    partner: 'Back-Office | Hello - Partners',
    program: 'Back-Office | Hello - Programs',
    publisher_category: 'Back-Office | Hello - Publisher Categories',
    space: 'Back-Office | Hello - Spaces',
  },
  partner: {
    add_partner_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        apply: {
          body_action_color: 'Body Action Color',
          header_background_color: 'Header Background Color',
          header_logo: 'Header Logo',
          header_text_color: 'Header Text Color',
          preview_page_active: 'Preview Page Active',
          title: 'Apply',
          url_en: 'Url EN',
          url_fr: 'Url FR',
        },
        business_id: 'Business ID',
        card_packages_en: 'Card Package EN',
        card_packages_fr: 'Card Package FR',
        connector_interfaces: {
          connector_name: 'Connector Name',
          title: 'Connector Interfaces',
        },
        content: {
          brand_color: 'Brand Color',
          default_location: {
            city: 'City',
            country: 'Country',
            lat: 'Latitude',
            lng: 'Longitude',
            state: 'State/Province',
            street: 'Street',
            title: 'Default Location',
            zip: 'Zip/Postal Code',
          },
          name_en: 'Name EN',
          name_fr: 'Name FR',
          program_url_en: 'Program Url EN',
          program_url_fr: 'Program Url FR',
          title: 'Content',
          url_en: 'Url EN',
          url_fr: 'Url FR',
        },
        features: {
          active: 'Active Feature',
          other: 'Other feature',
          title: 'Features',
        },
        id: 'ID',
        instant_card_packages_en: 'Instant Card Package EN',
        instant_card_packages_fr: 'Instant Card Package FR',
        instant_renew_card_packages_en: 'Instant Renew Card Package EN',
        instant_renew_card_packages_fr: 'Instant Renew Card Package FR',
        logo: {
          dark_background: 'Dark Background',
          horizontal_en: 'Horizontal EN',
          horizontal_fr: 'Horizontal FR',
          light_background: 'Light Background',
          square_en: 'Square EN',
          square_fr: 'Square FR',
          title: 'Logos',
        },
        name: 'Name',
        options: {
          allowed_card_package_ids: 'Allowed Card Pacakge Ids',
          card_activation: 'Card Activation',
          card_activation_reminder: 'Card Activation Reminder',
          card_application: 'Card Application',
          card_application_send_alert_on_approval: 'Send Alert On Approval',
          integration_jwt_secret: 'Integration JWT secret',
          invite_only: 'Invite Only',
          join_program: 'Join Program',
          manageable: 'Manageable',
          title: 'Options',
        },
        region: 'Region',
        renew_card_packages_en: 'Renew Card Package EN',
        renew_card_packages_fr: 'Renew Card Package FR',
        timezone: 'Timezone',
        webhooks: {
          authorization: 'Authorization',
          member_activity: 'Member Activity',
          membership_activity: 'Membership Activity',
          offer_activity: 'Offer Activity',
          title: 'Webhooks',
          uri: 'Uri',
        },
      },
      success: 'Partner Created',
      title: 'Add Partner',
    },
    form: {
      add: 'Add',
      clear: 'clear',
      label: 'Partner Name or ...?',
      submit: 'search',
      title: 'Search for an partner',
    },
    list: {
      title: 'Partners',
    },
    listTable: {
      business_id: 'Business ID',
      edit: 'Edit',
      id: 'Id',
      name: 'Name',
      region: 'Region',
      timezone: 'Timezone',
    },
    update_partner_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        apply: {
          body_action_color: 'Body Action Color',
          header_background_color: 'Header Background Color',
          header_logo: 'Header Logo',
          header_text_color: 'Header Text Color',
          preview_page_active: 'Preview Page Active',
          title: 'Apply',
          url_en: 'Url EN',
          url_fr: 'Url FR',
        },
        business_id: 'Business ID',
        card_packages_en: 'Card Package EN',
        card_packages_fr: 'Card Package FR',
        connector_interfaces: {
          connector_name: 'Connector Name',
          title: 'Connector Interfaces',
        },
        content: {
          brand_color: 'Brand Color',
          default_location: {
            city: 'City',
            country: 'Country',
            lat: 'Latitude',
            lng: 'Longitude',
            state: 'State/Province',
            street: 'Street',
            title: 'Default Location',
            zip: 'Zip/Postal Code',
          },
          name_en: 'Name EN',
          name_fr: 'Name FR',
          program_url_en: 'Program Url EN',
          program_url_fr: 'Program Url FR',
          title: 'Content',
          url_en: 'Url EN',
          url_fr: 'Url FR',
        },
        features: {
          active: 'Active Feature',
          other: 'Other feature',
          title: 'Features',
        },
        id: 'ID',
        instant_card_packages_en: 'Instant Card Package EN',
        instant_card_packages_fr: 'Instant Card Package FR',
        instant_renew_card_packages_en: 'Instant Renew Card Package EN',
        instant_renew_card_packages_fr: 'Instant Renew Card Package FR',
        logo: {
          dark_background: 'Dark Background',
          horizontal_en: 'Horizontal EN',
          horizontal_fr: 'Horizontal FR',
          light_background: 'Light Background',
          square_en: 'Square EN',
          square_fr: 'Square FR',
          title: 'Logos',
        },
        name: 'Name',
        options: {
          allowed_card_package_ids: 'Allowed Card Pacakge Ids',
          card_activation: 'Card Activation',
          card_activation_reminder: 'Card Activation Reminder',
          card_application: 'Card Application',
          card_application_send_alert_on_approval: 'Send Alert On Approval',
          integration_jwt_secret: 'Integration JWT secret',
          invite_only: 'Invite Only',
          join_program: 'Join Program',
          manageable: 'Manageable',
          title: 'Options',
        },
        region: 'Region',
        renew_card_packages_en: 'Renew Card Package EN',
        renew_card_packages_fr: 'Renew Card Package FR',
        timezone: 'Timezone',
        webhooks: {
          authorization: 'Authorization',
          member_activity: 'Member Activity',
          membership_activity: 'Membership Activity',
          offer_activity: 'Offer Activity',
          title: 'Webhooks',
          uri: 'Uri',
        },
      },
      success: 'Partner Updated',
      title: 'Update Partner',
    },
  },
  program: {
    add_program_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        currency: 'Currency',
        gps: {
          iss_code: 'ISS Code',
        },
        id: 'Id',
        limits: {
          card_max_balance: 'Card Max Balance',
          card_upper_max_balance: 'Card Upper Max Balance',
          minimal_authorization_amount: 'Minimal Authorization Amount',
          minimum_load_amount: 'Minimum Load Amount',
          open_wallet_max_balance: 'Open Wallet Max Balance',
          title: 'Limits',
        },
        name: 'Name',
        options: {
          allow_open_wallet_unload: 'Allow Open Wallet Unload',
          allow_partial_authorization: 'Allow Partial Authorization',
          exclude_padding_amount: 'Exclude Padding Amount',
          title: 'Options',
        },
        transaction_rules: {
          preauthorization: {
            blocked_mcc_codes: 'Blocked MCC Codes',
          },
        },
        type: {
          label: 'Type',
          options: {
            gpr: 'GPR',
            instant: 'Instant',
          },
        },
        velocity: {
          authorization_daily_maximum_amount: 'Authorization Daily Maximum Amount',
          load_daily_maximum_amount: 'Load Daily Maximum Amount',
          title: 'Velocity',
        },
        webhooks: {
          authorization: 'Authorization',
          authorization_alert: 'Authorization Alert',
          card_activity: 'Card Activity',
          card_application_process: 'Card Application Process',
          debits_and_credits_activity: 'Debits and Credits Activity',
          title: 'Webhooks',
          transaction_activity: 'Transactions Activity',
          uri: 'Uri',
        },
      },
      success: 'Card Program Created',
      title: 'Add Card Program',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Card Programs',
    },
    listTable: {
      edit: 'Edit',
      id: 'Id',
      key: 'Key',
      name: 'Name',
      type: 'Type',
    },
    permissions: {
      aml_officer: 'AML Officer',
      approve_instant_card_batch: 'Approve Instant Card Batch',
      beta: 'Beta',
      comment_application: 'Comment Application',
      comment_card: 'Comment Card',
      dev: 'Dev',
      internal: 'Internal',
      manage_alert: 'Manage Alert',
      manage_aml_program: 'Manage AML Program',
      manage_application: 'Manage Application',
      manage_card: 'Manage Card',
      manage_instant_card: 'Manage Instant Card',
      manage_merchant: 'Manage Merchant',
      manage_offer: 'Manage Offer',
      manage_reward: 'Manage Reward',
      real_time_notification: 'Real Time Notification',
      upload_document_application: 'Upload Document Application',
      upload_document_card: 'Upload Document Card',
      view_alert: 'View Alert',
      view_application: 'View Application',
      view_card: 'View Card',
      view_merchant: 'View Merchant',
      view_offer: 'View Offer',
      view_reward: 'View Reward',
      view_task: 'View Task',
      view_wallet_definition: 'View Wallet Definition',
    },
    update_program_action: {
      fields: {
        currency: 'Currency',
        gps: {
          iss_code: 'ISS Code',
        },
        limits: {
          card_max_balance: 'Card Max Balance',
          card_upper_max_balance: 'Card Upper Max Balance',
          minimal_authorization_amount: 'Minimal Authorization Amount',
          minimum_load_amount: 'Minimum Load Amount',
          open_wallet_max_balance: 'Open Wallet Max Balance',
          title: 'Limits',
        },
        name: 'Name',
        options: {
          allow_open_wallet_unload: 'Allow Open Wallet Unload',
          allow_partial_authorization: 'Allow Partial Authorization',
          exclude_padding_amount: 'Exclude Padding Amount',
          title: 'Options',
        },
        transaction_rules: {
          preauthorization: {
            blocked_mcc_codes: 'Blocked MCC Codes',
          },
        },
        velocity: {
          authorization_daily_maximum_amount: 'Authorization Daily Maximum Amount',
          load_daily_maximum_amount: 'Load Daily Maximum Amount',
          title: 'Velocity',
        },
        webhooks: {
          authorization: 'Authorization',
          authorization_alert: 'Authorization Alert',
          card_activity: 'Card Activity',
          card_application_process: 'Card Application Process',
          debits_and_credits_activity: 'Debits and Credits Activity',
          title: 'Webhooks',
          transaction_activity: 'Transactions Activity',
          uri: 'Uri',
        },
      },
      success: 'Card Program Updated',
      title: 'Update Card Program',
    },
  },
  scrollableModal: {
    defaultCloseText: 'Close',
  },
  selectText: {
    defaultText: 'Please select one',
  },
  space: {
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        branding: 'Branding ID',
        default_language: 'Default Language',
        id: 'ID',
        name: 'Name',
        partner_id: 'Partner ID',
        private_space: 'Private',
        slug: 'Slug',
      },
      success: 'Space Created',
      title: 'Add Space',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Spaces',
    },
    listTable: {
      branding_id: 'Branding ID',
      default_language: 'Default Language',
      edit: 'Edit',
      id: 'ID',
      name: 'Name',
      partner_id: 'Partner ID',
      private_space: 'Private',
      slug: 'Slug',
    },
    update_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        content: {
          background_image_url: 'Background Image Url',
          banner_image_url: 'Banner Image Url',
          primary_background_color: 'Primary Background Color',
          primary_color: 'Primary Color',
        },
        default_language: 'Default Language',
        id: 'ID',
        name: 'Name',
        partner_id: 'Partner ID',
        private_space: 'Private',
        slug: 'Slug',
      },
      success: 'Space Updated',
      title: 'Update Space',
    },
  },
  state: {
    alberta: 'Alberta',
    british_columbia: 'British Columbia',
    manitoba: 'Manitoba',
    new_brunswick: 'New Brunswick',
    newfoundland_labrador: 'Newfoundland and Labrador',
    northwest_territories: 'Northwest Territories',
    nova_scotia: 'Nova Scotia',
    nunavut: 'Nunavut',
    ontario: 'Ontario',
    prince_edward_island: 'Prince Edward Island',
    quebec: 'Quebec',
    sk: 'Saskatchewan',
    yukon: 'Yukon',
  },
  subscription_plan: {
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        monthly_fee: 'Monthly Fee',
        name: 'Name',
        partner_id: 'Partner Id',
      },
      success: 'Successfully added subscription plan',
      title: 'Create Subscription Plan',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Subscription Plans',
    },
    listTable: {
      edit_stripe_monthly_price: 'Edit Stripe Monthly Price',
      edit_stripe_product: 'Edit Stripe Product',
      id: 'Id',
      monthly_fee: 'Monthly Fee',
      name: 'Name',
      partner_id: 'Partner Id',
      status: 'Status',
    },
  },
  system: {
    dates: {
      ago: 'ago',
      day: 'day | days',
      month: 'month | months',
      year: 'year | years',
    },
    locale_changed: 'Locale Changed',
    na: 'N/A',
    none: 'None',
    undefined: 'Undefined',
  },
  toolbar: {
    title: 'Back-Office | Hello',
  },
  transactions: {
    list: {
      item: {
        amount: 'Amount',
        card_public_token: 'Card public Token',
        merchant_name: 'Merchant Name',
        transaction_date: 'Transaction Date',
        transaction_id: 'Transaction Id',
      },
      last_authorization: 'Last Authorization',
      last_ehi_timeout: 'Last Ehi Timeout',
      last_presentment: 'Last Presentment',
      title: 'Transactions',
    },
  },
  virtual_money: {
    activate_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      success: 'Virtual Money Activated',
      text: 'Are you sure you want to activate this virtual money?',
      title: 'Activate Virtual Money',
    },
    add_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      fields: {
        id: 'ID',
        name: 'Name',
        option_auto_adjustment_other_card_program: 'Auto Adjustment Other Card Program',
        option_create_on_account_creation: 'Create on Account Creation',
        option_load_on_card_program_id: 'Load on Card Program ID',
        wallet_definition_id: 'Wallet Definition ID',
      },
      success: 'Virtual Money Created',
      title: 'Edit Virtual Money',
    },
    deactivate_action: {
      button_cancel: 'Cancel',
      button_save: 'Save',
      success: 'Virtual Money Deactivated',
      text: 'Are you sure you want to deactivate this virtual money?',
      title: 'Deactivate Virtual Money',
    },
    form: {
      add: 'Add',
    },
    list: {
      title: 'Virtual Moneys',
    },
    listTable: {
      activate: 'Activate',
      auto_adjustment_other_card_program: 'Auto Adjustment Other Card Program',
      create_on_account_creation: 'Create on Account Creation',
      deactivate: 'Deactivate',
      edit: 'Edit',
      id: 'ID',
      load_on_card_program: 'Load On Card Program ID',
      name: 'Name',
      status: 'Status',
      status_values: {
        active: 'Active',
        deactivated: 'Deactivated',
        pending: 'Pending',
      },
      wallet_definition: 'Wallet Definition ID',
    },
    update_action: {
      fields: {
        name: 'Name',
        option_auto_adjustment_other_card_program: 'Auto Adjustment Other Card Program',
        option_create_on_account_creation: 'Create on Account Creation',
        option_load_on_card_program_id: 'Load on Card Program ID',
        wallet_definition_id: 'Wallet Definition ID',
      },
      success: 'Virtual Money Updated',
      title: 'Edit Virtual Money',
    },
  },
};
